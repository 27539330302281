import React, { useCallback } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../rootReducer';
import {
  fetchBasicUserInfo,
  initializeUserInfo,
} from '../shared/basicUserInfo.action';
import { JwtClient } from '../../clients/JwtClient';
import { intersection } from 'lodash';
import { setCredentials } from '../shared/credentials.action';
import { CredentialsService } from '../../service/CredentialsService';
import { AuthContextProvider } from '../context/AuthContext';
import { Role, SuccessfulAuthResponse } from '../../service/service.types';
import { withTracker } from 'ga-4-react';
import { getUserInformation } from '../shared/selectors/getUserInformation';
import styles from './Login/SuccessfulLogin/SuccessfulLogin.module.scss';
import { NGlicSpinner } from '@nglic/component-lib/build';

export enum AOPaths {
  LANDING_PAGE = '/',
  LOGIN = '/login',
  CONTRACT = '/contract',
  AGENT_MANAGEMENT = '/agent',
  MANAGE_HIERARCHIES = '/manage-hierarchies',
  PROFILE = '/profile',
  MANAGE_BUSINESS = '/manage-business',
  EDIT_PROFILE = '/edit-profile',
  SUPPORT = '/support',
}

export enum AOSpecialRoutes {
  AGENT_MANAGEMENT_SUBPAGE = '/agent/add-agent/',
  CONTRACT_SUBPAGE = '/contract/',
  MANAGE_BUSINESS_SUBPAGE = '/manage-business/',
  EDIT_PROFILE_SUBPAGE = '/profile/edit-profile/',
}

const PageRoleMapping: Partial<
  Record<AOPaths | AOSpecialRoutes, { included: Role[]; excluded?: Role[] }>
> = {
  [AOPaths.LOGIN]: { included: [] },
  [AOPaths.PROFILE]: { included: [] },
  [AOPaths.LANDING_PAGE]: { included: [] },
  [AOPaths.CONTRACT]: { included: [] },
  [AOPaths.AGENT_MANAGEMENT]: {
    included: [Role.CONTRACT_MANAGERS, Role.ADMIN],
  },
  [AOPaths.MANAGE_BUSINESS]: {
    included: [],
    excluded: [Role.CONTRACT_MANAGERS],
  },
  [AOPaths.SUPPORT]: {
    included: [Role.SUPPORT],
  },

  //Routes with subpages
  [AOSpecialRoutes.AGENT_MANAGEMENT_SUBPAGE]: {
    included: [Role.CONTRACT_MANAGERS, Role.ADMIN],
  },
  [AOPaths.MANAGE_HIERARCHIES]: {
    included: [Role.CONTRACT_MANAGERS, Role.ADMIN],
  },
  [AOSpecialRoutes.CONTRACT_SUBPAGE]: {
    included: [],
  },
  [AOSpecialRoutes.MANAGE_BUSINESS_SUBPAGE]: {
    included: [],
    excluded: [Role.CONTRACT_MANAGERS],
  },

  [AOSpecialRoutes.EDIT_PROFILE_SUBPAGE]: {
    included: [],
  },
};

export const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const history = useHistory();
  const { accessToken, idToken, refreshToken } = useSelector(
    (state: AppState) => state.data.user.credentials,
  );
  const user = useSelector((state: AppState) => getUserInformation(state));
  const [authState, setAuthState] = React.useState({
    authenticated: false,
    authorized: false,
    loading: true,
    decodedToken: {},
  });

  const TrackedComponent = withTracker(Component);

  const dispatch = useDispatch();
  const handleInvalidToken = () => {
    setAuthState({
      decodedToken: {},
      authenticated: false,
      authorized: false,
      loading: false,
    });
  };

  const handleValidToken = (
    decodedIdToken: Record<string, any>,
    credentials: SuccessfulAuthResponse,
  ) => {
    dispatch(
      setCredentials(
        credentials.accessToken,
        credentials.idToken,
        credentials.refreshToken,
      ),
    );

    if (!user) {
      setAuthState({
        decodedToken: decodedIdToken,
        authenticated: true,
        authorized: false,
        loading: true,
      });
    } else {
      setAuthState({
        decodedToken: decodedIdToken,
        authenticated: true,
        authorized: checkPageAccessControlByUserRole(user.roles, path),
        loading: false,
      });
    }
  };

  React.useEffect(() => {
    if (authState.authenticated && !user) {
      dispatch(
        initializeUserInfo(
          authState.decodedToken['cognito:groups'],
          authState.decodedToken['email'],
        ),
      );
    }
  }, [authState]);

  React.useEffect(() => {
    /* We may need to refresh the tokens we have so creating local var we can override if
     * necessary without having to reload state
     */
    let tokens = {
      idToken,
      accessToken,
      refreshToken,
    };

    JwtClient.verifyAccessToken(accessToken)
      .then(async (isValid) => {
        if (isValid) {
          return isValid;
        } else if (!refreshToken || refreshToken.length === 0) {
          return isValid;
        }

        try {
          tokens = await CredentialsService.refreshTokens(refreshToken);
          return true;
        } catch (e) {
          return false;
        }
      })
      .then(async (valid) => {
        if (!valid) {
          handleInvalidToken();
        } else {
          const decodedToken = await JwtClient.decodeToken(tokens.idToken);
          handleValidToken(decodedToken, tokens);
        }
      });
  }, [user]);

  return (
    <Route
      data-testid="protected-route"
      {...rest}
      render={({ location, ...remainingProps }) => {
        if (
          authState.authenticated &&
          authState.authorized &&
          !authState.loading
        ) {
          return (
            <AuthContextProvider value={authState.decodedToken}>
              <TrackedComponent
                path={path}
                location={location.pathname + location.search}
                {...remainingProps}
              />
            </AuthContextProvider>
          );
        } else if (
          authState.authenticated &&
          !authState.authorized &&
          !authState.loading
        ) {
          history.push('/not-authorized');
        } else if (
          !accessToken ||
          !accessToken.length ||
          (!authState.authenticated &&
            !authState.authorized &&
            !authState.loading)
        ) {
          history.replace('/login');
        } else if (
          (authState.loading && !authState.authenticated) ||
          authState.loading
        ) {
          return (
            <div className={styles['loading-container']}>
              <NGlicSpinner />
            </div>
          );
        }
      }}
    />
  );
};

const checkPageAccessControlByUserRole = (
  Role: string[],
  requestedPage: string,
): boolean => {
  if (Role?.includes('admin')) {
    return true;
  }

  const roles: { included: Role[]; excluded?: Role[] } | undefined =
    isSpecialRoute(requestedPage)
      ? getRoleForSpecialRoute(requestedPage)
      : PageRoleMapping[requestedPage];

  if (!roles) {
    return false;
  }

  return isIncluded(Role, roles.included) && !isExcluded(Role, roles.excluded);
};

const isIncluded = (Role: string[], neededRoles: Role[]): boolean => {
  if (neededRoles.length === 0) {
    return true; // Basic user case
  }

  return intersection(Role, neededRoles).length > 0;
};

const isExcluded = (Role: string[], excludedRoles?: string[]): boolean => {
  if (!excludedRoles) {
    return false;
  }

  return intersection(Role, excludedRoles).length > 0;
};

const isSpecialRoute = (path) => {
  switch (true) {
    case path.includes(AOSpecialRoutes.AGENT_MANAGEMENT_SUBPAGE):
      return true;
    case path.includes(AOSpecialRoutes.CONTRACT_SUBPAGE):
      return true;
    case path.includes(AOSpecialRoutes.MANAGE_BUSINESS_SUBPAGE):
      return true;
    case path.includes(AOSpecialRoutes.EDIT_PROFILE_SUBPAGE):
      return true;
  }
};

const getRoleForSpecialRoute = (
  path,
): { included: Role[]; excluded?: Role[] } | undefined => {
  switch (true) {
    case path.includes(AOSpecialRoutes.AGENT_MANAGEMENT_SUBPAGE):
      return PageRoleMapping[AOSpecialRoutes.AGENT_MANAGEMENT_SUBPAGE];
    case path.includes(AOSpecialRoutes.CONTRACT_SUBPAGE):
      return PageRoleMapping[AOSpecialRoutes.CONTRACT_SUBPAGE];
    case path.includes(AOSpecialRoutes.MANAGE_BUSINESS_SUBPAGE):
      return PageRoleMapping[AOSpecialRoutes.MANAGE_BUSINESS_SUBPAGE];
    case path.includes(AOSpecialRoutes.EDIT_PROFILE_SUBPAGE):
      return PageRoleMapping[AOSpecialRoutes.EDIT_PROFILE_SUBPAGE];
  }
};
